import type { SubscriptionSharingStatus } from '@orus.eu/backend/src/views/subscriptions/subscription-view'
import { assert } from '../../lib/errors'

export function getDisabledReasonTooltip(quoteSharingStatus: SubscriptionSharingStatus & { type: 'disabled' }): string {
  switch (quoteSharingStatus.reason) {
    case 'already-signed':
    case 'exclusion':
    case 'unsaved-changes':
      return disabledReasonsTooltips[quoteSharingStatus.reason]
    case 'missing-input':
      assert(quoteSharingStatus.missingFields.length > 0, 'missingFields should not be empty')
      return quoteSharingStatus.missingFields.length > 1
        ? `Il manque les champs suivants : ${quoteSharingStatus.missingFields.join(', ')}`
        : `Il manque le champ ${quoteSharingStatus.missingFields[0]}`
  }
}

export const disabledReasonsTooltips = {
  'already-signed': "L'utilisateur a déjà signé. Inutile de renvoyer le devis !",
  exclusion: "Il faut traiter l'exclusion avant de partager le devis !",
  'unsaved-changes': 'Il faut sauvegarder les changements avant de partager le devis !',
} as const

export const noQuoteDisabledReasonTooltip = "Assure-toi d'avoir un prix avant de partager le devis !"
export const noEmailDisabledReasonTooltip = "Saisis l'email avant de partager le devis !"
export const noPhoneDisabledReasonTooltip = 'Saisis le numéro de téléphone avant de partager le devis !'
